import axios from 'axios';
import { Order, TokenItem, TokenItemV2 } from '../interfaces/token';
import {
  MOCK_ORDERS,
  MOCK_TOKEN,
  MOCK_TOKENS_V2,
  USE_MOCK_DATA,
} from '../utils/mocks';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.4.fun';

export async function fetchTokens(limit: number = 12): Promise<TokenItem[]> {
  try {
    const response = await axios.get(
      `${API_URL}/geyser/token/latest?limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}

export async function fetchLatestTokens(): Promise<TokenItemV2[]> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_TOKENS_V2;
    }
    const response = await axios.get(
      `${API_URL}/geyser/token/top/latest-tokens`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}

export async function fetchBoundingRateTokens(): Promise<TokenItemV2[]> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_TOKENS_V2;
    }
    const response = await axios.get(
      `${API_URL}/geyser/token/top/bounding-rate`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}

export async function fetchKOTHTokens(): Promise<TokenItemV2[]> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_TOKENS_V2;
    }
    const response = await axios.get(`${API_URL}/geyser/token/top/koth`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}

export async function fetchTransactionCountTokens(): Promise<TokenItemV2[]> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_TOKENS_V2;
    }
    const response = await axios.get(
      `${API_URL}/geyser/token/top/transaction-count`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}

export async function fetchRecentlyAddedTokens(): Promise<TokenItemV2[]> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_TOKENS_V2;
    }
    const response = await axios.get(
      `${API_URL}/geyser/token/top/recently-added`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}

export async function fetchMarketCapTokens(): Promise<TokenItemV2[]> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_TOKENS_V2;
    }
    const response = await axios.get(`${API_URL}/geyser/token/top/market-cap`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}

export async function fetchToken(mint: string): Promise<TokenItem | null> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_TOKEN;
    }
    const response = await axios.get(`${API_URL}/geyser/token/mint/${mint}`);
    console.log('fetchToken', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return null;
  }
}

export async function fetchOrders(mint: string): Promise<Order[]> {
  try {
    if (USE_MOCK_DATA) {
      return MOCK_ORDERS;
    }
    const response = await axios.get(`${API_URL}/geyser/order/latest/${mint}`);
    console.log('fetchToken', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    return [];
  }
}
