import styles from './styles.module.css';
import Search22 from '../svg/Search22';
import { useSearchContext } from '../../providers/search';

export const Search = () => {
  const { setSearchValue, searchValue } = useSearchContext();

  return (
    <div className={styles.search}>
      <Search22 />
      <input
        type="text"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </div>
  );
};
