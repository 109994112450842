import { Link, useParams } from 'react-router-dom';
import { createChart, ISeriesApi } from 'lightweight-charts';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Col } from '../../components/Col';
import { Container } from '../../components/Container';
import { Input } from '../../components/Input';
import { ProgressBar } from '../../components/ProgressBar';
import { Row } from '../../components/Row';
import Telegram from '../../components/svg/Telegram';
import Twitter from '../../components/svg/Twitter';
import styles from './styles.module.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransactionTable } from '../../containers/TransactionTable';
import { fetchOrders, fetchToken } from '../../services/tokenService';
import { Order, TokenItem } from '../../interfaces/token';
import { createCandles } from '../../utils/create-candles';
import { Candle } from '../../utils/types';
import { useDataContext } from '../../providers/data';
import Heart from '../../components/svg/Heart';
import PlusSmall from '../../components/svg/PlusSmall';
import { useWindowSize } from '../../hooks/useWindowSize';
import { BottomTabs } from '../../components/BottomTabs';
import Zap from '../../components/svg/Zap';
import Chart from '../../components/svg/Chart';
import Trade from '../../components/svg/Trade';
import Table from '../../components/svg/Table';
import { buyToken, getTokens, sellToken } from '../../services/web3Service';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import toast from 'react-hot-toast';
import { useModalContext } from '../../providers/modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import Close from '../../components/svg/Close';
import { Loader } from '../../components/Loader';
import Success from '../../components/svg/Success';

enum TradeType {
  Buy,
  Sell,
}

export const Detail = () => {
  const { setContent, setIsModalOpen } = useModalContext();
  const chartRef = useRef<HTMLDivElement>(null);
  const [token, setToken] = useState<TokenItem | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);
  const [series, setSeries] = useState<ISeriesApi<'Candlestick', any> | null>(
    null
  );
  const [prices, setPrices] = useState<Array<Candle> | null>(null);
  const { mint } = useParams<{ mint: string }>();
  const { isLoading, orderSubscribe, orderUnsubscribe } = useDataContext();
  const wallet = useWallet();
  const { width } = useWindowSize();
  const [selectedTab, setSelectedTab] = useState(0);
  const [tradeType, setTradeType] = useState<TradeType>(TradeType.Buy);
  const [tradeAmount, setTradeAmount] = useState<string | undefined>();
  const [tokenAmount, setTokenAmount] = useState<string | undefined>('0');
  const [trading, setTrading] = useState(false);
  const parsePercent = useCallback(
    (percent: number) => {
      return Number(tokenAmount) === 0
        ? '0.0'
        : parseFloat(`${Number(tokenAmount) * percent}`).toPrecision(12);
    },
    [tokenAmount]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (chartRef.current) {
      const chart = createChart(chartRef.current, {
        width: chartRef.current.clientWidth,
        height: chartRef.current.clientHeight,
        timeScale: {
          timeVisible: true,
        },
        autoSize: true,
        layout: {
          background: {
            color: 'transparent',
          },
          textColor: '#fff',
        },
      });
      const series = chart.addCandlestickSeries({
        priceFormat: {
          type: 'price',
          precision: 10,
          minMove: 0.000_000_000_1,
        },
        upColor: '#00FF94',
        downColor: '#FC6868',
      });
      setSeries(series);
    }
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chartRef.current.innerHTML = '';
      }
    };
  }, [chartRef]);

  useEffect(() => {
    if (!series || !prices?.length) {
      return;
    }

    series.setData(prices);
  }, [prices, series]);

  useEffect(() => {
    if (!series || !orders?.length) {
      return; // loading
    }

    console.log({ orders });

    const prices = createCandles(orders, {
      duration: 60,
    });

    setPrices(prices);
  }, [series, orders]);

  useEffect(() => {
    console.log({ mint, isLoading });

    const fetchData = async () => {
      try {
        const data = await fetchToken(mint || '');
        const orderData = await fetchOrders(mint || '');
        console.log({ data });
        setToken(data);
        setOrders(orderData);
        if (localStorage.getItem('createdToken')) {
          localStorage.removeItem('createdToken');
        }
      } catch (error: any) {
        console.log('Token is not created yet');
        const createdToken = localStorage.getItem('createdToken');
        if (createdToken === mint) {
          console.log('retrying', createdToken);
          fetchData();
        } else {
          toast.error(error.message);
        }
      }
    };
    if (!mint || isLoading) {
      return;
    }
    fetchData();

    orderSubscribe(mint, (data) => {
      console.log('orderSubscribe', data);
      const order: Order = data.order;
      if (!order) {
        console.log('no order in data', data);
        return;
      }
      setOrders((prev) =>
        [order, ...prev].sort((a, b) =>
          new Date(a.time).getTime() < new Date(b.time).getTime() ? 1 : -1
        )
      );
    });

    return () => {
      orderUnsubscribe(mint);
    };
  }, [mint, isLoading]);

  const fetchTokenData = useCallback(async () => {
    if (!mint || !wallet?.publicKey) {
      return;
    }
    const data = await getTokens(wallet, mint);
    if (data?.tokenBalance?.value?.uiAmountString) {
      setTokenAmount(data.tokenBalance.value.uiAmountString);
    }
  }, [wallet, mint]);

  useEffect(() => {
    fetchTokenData();
  }, [wallet, mint]);

  useEffect(() => {
    setTradeAmount('');
  }, [tradeType]);

  const onSubmit: SubmitHandler<any> = useCallback(() => {
    try {
      toast.success('Comment posted');
      setIsModalOpen(false);
      setContent(null);
      reset();
    } catch (error: any) {
      toast.error(error.message);
    }
  }, [setContent, setIsModalOpen, reset]);

  const comment = useCallback(() => {
    setContent(
      <Card className={styles.commentModal}>
        <div className={styles.header}>
          <div className={styles.title}>Add comment</div>
          <div
            className={styles.close}
            onClick={() => {
              setIsModalOpen(false);
              setContent(null);
            }}
          >
            <Close />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.commentForm}>
          <label className={styles.inputContainer}>
            <textarea
              className={styles.textarea}
              placeholder="Write your comment here"
              {...register('commentText', { required: true })}
            />
            {errors.commentText && (
              <span className={styles.error}>This field is required</span>
            )}
          </label>
          <Button type="submit" className={styles.submitButton}>
            Post comment
          </Button>
        </form>
      </Card>
    );
    setIsModalOpen(true);
  }, [setContent, setIsModalOpen, errors, handleSubmit, onSubmit, register]);

  const trade = useCallback(async () => {
    let multiplier = 1;
    let tradeToast = '';
    try {
      if (!mint) {
        toast.error('no mint');
        return;
      }
      if (!wallet) {
        toast.error('no wallet');
        return;
      }
      if (!tradeAmount) {
        toast.error('no trade amount');
        return;
      }
      if (!token) {
        toast.error('missing token info');
        return;
      }
      if (tradeAmount.indexOf('.') !== -1) {
        multiplier = Math.pow(10, tradeAmount.split('.')[1].length);
      }
      const amount =
        ((tradeType === TradeType.Sell ? 1_000_000n : 1_000_000_000n) *
          BigInt(Number(tradeAmount) * multiplier)) /
        BigInt(multiplier);
      setTrading(true);
      tradeToast = toast.loading(
        <div className={styles.toastContainer}>
          <div className={styles.toastInfoBlock}>
            <div className={styles.toastTradeInfo}>
              {tradeType === TradeType.Buy ? 'Buy ' : 'Sell '}
              {tradeType === TradeType.Buy
                ? parseFloat(
                    `${Number(tradeAmount) / token.stats.price}`
                  ).toPrecision(12)
                : tradeAmount}{' '}
              {token?.token.meta.symbol || ''} for
            </div>
            <div className={styles.toastTradeInfo}>
              {tradeType === TradeType.Buy
                ? tradeAmount
                : parseFloat(
                    `${Number(tradeAmount) * token.stats.price}`
                  ).toPrecision(12)}{' '}
              SOL
            </div>
            <div className={styles.toastTradeStatus}>
              <Loader /> <span>Confirming transaction</span>
            </div>
          </div>
          <Link
            to={`https://solscan.io/token/${mint}`}
            target="_blank"
            className={styles.toastLink}
          >
            <Button className={styles.toastViewTx}>View tx</Button>
          </Link>
        </div>
      );
      const request = async () => {
        if (tradeType === TradeType.Sell) {
          const result = await sellToken(wallet, {
            mint: new PublicKey(mint),
            tokenToSpend: amount,
            minSolToReceive: 0n, // todo: what is the proper value?
          });
          console.log(result);
        } else if (tradeType === TradeType.Buy) {
          const result = await buyToken(wallet, {
            mint: new PublicKey(mint),
            solToSpend: amount,
            minTokenToReceive: 0n, // todo: what is the proper value?
          });
          console.log(result);
        }
      };
      await request();
      toast.success(
        <div className={styles.toastContainer}>
          <div className={styles.toastInfoBlock}>
            <div className={styles.toastTradeInfo}>
              {tradeType === TradeType.Buy ? 'Buy ' : 'Sell '}
              {tradeType === TradeType.Buy
                ? parseFloat(
                    `${Number(tradeAmount) / token.stats.price}`
                  ).toPrecision(12)
                : tradeAmount}{' '}
              {token?.token.meta.symbol || ''} for
            </div>
            <div className={styles.toastTradeInfo} style={{ color: '#00FF94' }}>
              {tradeType === TradeType.Buy
                ? tradeAmount
                : parseFloat(
                    `${Number(tradeAmount) * token.stats.price}`
                  ).toPrecision(12)}{' '}
              SOL
            </div>
            <div
              className={styles.toastTradeStatus}
              style={{ color: '#00FF94' }}
            >
              <Success /> <span>Transaction confirmed</span>
            </div>
          </div>
          <Link
            to={`https://solscan.io/token/${mint}`}
            target="_blank"
            className={styles.toastLink}
          >
            <Button className={styles.toastViewTx}>View tx</Button>
          </Link>
        </div>,
        { id: tradeToast, duration: 10000 }
      );
      setTrading(false);
      fetchTokenData();
    } catch (error: any) {
      setTrading(false);
      toast.dismiss(tradeToast);
      console.log(error);
      toast.error(
        `${tradeType === TradeType.Buy ? 'Buy' : 'Sell'} transaction failed: ${
          String(error).indexOf('insufficient funds') !== -1
            ? 'Insufficient funds'
            : 'something went wrong'
        }`
      );
    }
  }, [tradeType, mint, wallet, tradeAmount, token]);

  const tradeBlock = useMemo(
    () => (
      <Card className={styles.tradeCard}>
        <div className={styles.buySellWrapper}>
          <Button
            className={`${styles.buyTab} ${
              tradeType === TradeType.Buy ? '' : styles.inactiveTab
            }`}
            onClick={() => setTradeType(TradeType.Buy)}
          >
            Buy
          </Button>
          <Button
            className={`${styles.sellTab} ${
              tradeType === TradeType.Sell ? '' : styles.inactiveTab
            }`}
            onClick={() => setTradeType(TradeType.Sell)}
          >
            Sell
          </Button>
        </div>
        {tradeType === TradeType.Buy && (
          <>
            <Input
              placeholder="0.0"
              className={styles.tradeInput}
              type="number"
              postfix="SOL"
              value={tradeAmount}
              onChange={setTradeAmount}
            />
            <div className={styles.chipsWrapper}>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount('0')}
              >
                reset
              </Button>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount('0.1')}
              >
                0.1 SOL
              </Button>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount('0.5')}
              >
                0.5 SOL
              </Button>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount('1')}
              >
                1 SOL
              </Button>
            </div>
          </>
        )}
        {tradeType === TradeType.Sell && (
          <>
            <Input
              placeholder="0.0"
              className={styles.tradeInput}
              type="number"
              value={tradeAmount}
              onChange={setTradeAmount}
              postfix={token?.token.meta.symbol || ''} // todo: check if this is correct
            />
            <div className={styles.chipsWrapper}>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount(parsePercent(0.01))}
              >
                1%
              </Button>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount(parsePercent(0.02))}
              >
                2%
              </Button>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount(parsePercent(0.05))}
              >
                5%
              </Button>
              <Button
                className={styles.chipButton}
                onClick={() => setTradeAmount(parsePercent(0.1))}
              >
                10%
              </Button>
            </div>
          </>
        )}
        <Button
          className={styles.tradeButton}
          onClick={trade}
          disabled={trading}
        >
          Trade
        </Button>
      </Card>
    ),
    [
      tradeType,
      trade,
      tradeAmount,
      token,
      setTradeAmount,
      trading,
      parsePercent,
    ]
  );

  const infoBlock = useMemo(
    () => (
      <>
        <div className={styles.links}>
          <a
            className={styles.socialLink}
            target="_blank"
            rel="noopener noreferrer"
            href="/"
          >
            SolGenAI.com
          </a>
          <a
            className={styles.socialLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com"
          >
            <Twitter width={24} height={24} />
            SolGenAI
          </a>
          <a
            className={styles.socialLink}
            href={'https://telegram.org'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Telegram width={24} height={24} />
            SolGenAI
          </a>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoImage}>
            <Avatar
              src={token?.token.meta.imageUrl || ''}
              size={133}
              noborder
            />
          </div>
          <div className={styles.info}>
            <h3 className={styles.infoName}>{token?.token.meta.name || ''}</h3>
            <p className={styles.infoDescription}>
              {token?.token.meta.description || ''}
            </p>
          </div>
        </div>
        <div className={styles.bondingWrapper}>
          <h4 className={styles.percent}>
            Bonding curve progress:{' '}
            {Math.round((token?.stats.bondingRate || 0) * 100) || 0}%
          </h4>
          <ProgressBar
            progress={Math.round((token?.stats.bondingRate || 0) * 100) || 0}
            withLabel={false}
          />
          <div className={styles.bondingInfo}>
            when the market cap reaches $0 all the liquidity from the bonding
            curve will be deposited into Raydium and burned. progression
            increases as the price goes up. there are 0 tokens still available
            for sale in the bonding curve and there is 0 SOL in the bonding
            curve.
          </div>
        </div>
      </>
    ),
    [token]
  );

  const chartBlock = useMemo(
    () => (
      <div className={styles.chartWrapper}>
        <div ref={chartRef} className={styles.chart} />
      </div>
    ),
    [chartRef]
  );

  const ordersBlock = useMemo(
    () => <TransactionTable data={orders} tokenName={token?.token.meta.name} />,
    [orders, token]
  );

  const commentBlock = useMemo(
    () => (
      <Card className={styles.commentCard}>
        <Button className={styles.commentButton} onClick={comment}>
          <span>Comment</span>
          <div>
            <PlusSmall />
          </div>
        </Button>
        <div className={styles.replyWrapper}>
          <div className={styles.replyHeader}>
            <Avatar src={'/doge.png'} size={24} type={'circle'} />
            <span className={styles.replyAuthor}>Kopernick</span>
            <span>24:02</span>
            <span>Reply</span>
            <span>
              <Heart />
              1111
            </span>
          </div>
          <div className={styles.replyContent}>
            When the market cap reached zero all the liquidity from the bonding
            curve will be deposited inti Raydium and burn progression increases
            as the price goes up.When the market cap reached zero all the
            liquidity from the bonding curve will be deposited inti Raydium and
            burn progression increases as the price goes up.
          </div>
        </div>
      </Card>
    ),
    [comment]
  );

  const bottomTabs = [
    {
      label: 'info',
      icon: <Zap />,
    },
    {
      label: 'chart',
      icon: <Chart />,
    },
    {
      label: 'trade',
      icon: <Trade />,
    },
    {
      label: 'txs',
      icon: <Table />,
    },
  ];

  return (
    <div className={styles.container}>
      <Container>
        {width > 1024 ? (
          <div className={styles.desktopGrid}>
            <Row>
              <Col size={8} lg={{ size: 7 }}>
                {chartBlock}
              </Col>
              <Col size={4} lg={{ size: 5 }}>
                {tradeBlock}
                {infoBlock}
              </Col>
            </Row>
            <Row>
              <Col size={8} lg={{ size: 7 }}>
                {ordersBlock}
              </Col>
              <Col size={4} lg={{ size: 5 }}>
                {commentBlock}
              </Col>
            </Row>
          </div>
        ) : (
          <div className={styles.mobileTabContent}>
            {selectedTab === 0 && infoBlock}
            {selectedTab === 1 && chartBlock}
            {selectedTab === 2 && (
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 23 }}
              >
                {tradeBlock}
                {commentBlock}
              </div>
            )}
            {selectedTab === 3 && ordersBlock}
          </div>
        )}
      </Container>
      {width && width <= 1024 && (
        <BottomTabs
          items={bottomTabs}
          selected={selectedTab}
          onChange={setSelectedTab}
        />
      )}
    </div>
  );
};
