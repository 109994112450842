import { useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Card } from '../../components/Card';
import { Container } from '../../components/Container';
import styles from './styles.module.css';
import { Button } from '../../components/Button';
import Close from '../../components/svg/Close';
import { Link, useNavigate } from 'react-router-dom';
import ArrowDown from '../../components/svg/ArrowDown';
import ArrowUp from '../../components/svg/ArrowUp';
import Plus from '../../components/svg/Plus';
import { useWallet } from '@solana/wallet-adapter-react';
import { createToken } from '../../services/web3Service';
import {
  CreateTokenFormData,
  uploadIpfsData,
} from '../../services/ipfsService';
import toast from 'react-hot-toast';
import { Loader } from '../../components/Loader';

interface IpfsResponse {
  IpfsHash: string;
  PinSize: number;
  Timestamp: string;
}

export const Create = () => {
  const navigate = useNavigate();
  const wallet = useWallet();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const watchFields = watch(['tokenImage']);
  const [isMoreOptions, setIsMoreOptions] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [detailLink, setDetailLink] = useState('');

  const onSubmit: SubmitHandler<any> = (data: CreateTokenFormData) => {
    try {
      setIsSubmitting(true);
      const requestData = async (data: CreateTokenFormData) => {
        let ipfsData: IpfsResponse = await uploadIpfsData(data);
        const result = await createToken(wallet, {
          name: data.tokenName,
          symbol: data.tokenTicker,
          uri: ipfsData.IpfsHash,
        });
        const mint = await result.toString();
        return mint;
      };
      toast.promise(
        requestData(data),
        {
          loading: (
            <div className={styles.toastLoading}>
              <Loader />
              <span>Loading...</span>
            </div>
          ),
          success: (data) => {
            setIsSubmitting(false);
            setDetailLink(`/${data}`);
            localStorage.setItem('createdToken', data);
            return 'Token created!';
          },
          error: (err) => {
            setIsSubmitting(false);
            console.log(err);
            return err.message;
          },
        },
        {
          success: {
            duration: 5000,
            icon: '🔥',
          },
        }
      );
    } catch (error: any) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const imageUploaded = useMemo(() => {
    return watchFields[0]?.length ? true : false;
  }, [watchFields]);

  useEffect(() => {
    console.log(watchFields);
  }, [watchFields]);

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.col}>
          <Card className={styles.card}>
            <div className={styles.header}>
              <div className={styles.title}>Create token</div>
              <Link
                to={'..'}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className={styles.close}
              >
                <Close />
              </Link>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <label className={styles.inputContainer}>
                Name*
                <input
                  type="text"
                  className={styles.input}
                  placeholder="Enter the token name"
                  {...register('tokenName', {
                    required: true,
                    validate: (value) =>
                      value.length <= 128 || 'Token name must be less than 128',
                  })}
                />
                {errors.tokenName && (
                  <span className={styles.error}>
                    {errors.tokenName.message?.toString() ||
                      'This field is required'}
                  </span>
                )}
              </label>

              <label className={styles.inputContainer}>
                Ticker*
                <input
                  type="text"
                  className={styles.input}
                  placeholder="Enter the token ticker"
                  {...register('tokenTicker', { required: true })}
                />
                {errors.tokenTicker && (
                  <span className={styles.error}>This field is required</span>
                )}
              </label>
              <div className={styles.inputContainer}>
                Image*
                <input
                  type="file"
                  id="file"
                  className={styles.input}
                  accept="image/jpeg, image/png"
                  {...register('tokenImage', {
                    required: true,
                    validate: (value) =>
                      value[0].size <= 10000000 || 'File size is too big',
                  })}
                />
                {!imageUploaded && <div className={styles.inputFile} />}
                <label htmlFor="file" className={styles.fileLabel}>
                  upload image
                  <Plus style={{ width: 31, height: 31 }} />
                </label>
                {errors.tokenImage && (
                  <span className={styles.error}>
                    {errors.tokenImage.message?.toString() ||
                      'This field is required'}
                  </span>
                )}
              </div>
              <label className={styles.inputContainer}>
                Description*
                <textarea
                  className={styles.textarea}
                  placeholder="Write the token description"
                  {...register('tokenDescription', { required: true })}
                />
                {errors.tokenDescription && (
                  <span className={styles.error}>This field is required</span>
                )}
              </label>
              <div
                className={styles.moreOptionsSpoiler}
                onClick={() => setIsMoreOptions(!isMoreOptions)}
              >
                {isMoreOptions ? (
                  <ArrowUp
                    style={{ width: 16, height: 16, color: '#00FF94' }}
                  />
                ) : (
                  <ArrowDown
                    style={{ width: 16, height: 16, color: '#00FF94' }}
                  />
                )}
                <span>{isMoreOptions && 'hide'} more options</span>
              </div>
              {isMoreOptions && (
                <>
                  <label className={styles.inputContainer}>
                    twitter link
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="optional"
                      {...register('twitterLink')}
                    />
                  </label>
                  <label className={styles.inputContainer}>
                    telegram link
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="optional"
                      {...register('telegramLink')}
                    />
                  </label>
                  <label className={styles.inputContainer}>
                    website
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="optional"
                      {...register('website')}
                    />
                  </label>
                </>
              )}
              <Button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting}
              >
                Create token
              </Button>
              {detailLink && (
                <Link
                  to={detailLink}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.detailLink}
                >
                  View token detail
                </Link>
              )}
              <div className={styles.disclaimerWrapper}>
                <div className={styles.disclaimerTitle}>Cost to deploy</div>
                <div className={styles.disclaimerText}>-0,02S SOL</div>
              </div>
            </form>
          </Card>
        </div>
      </Container>
    </div>
  );
};
