import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Root } from './routes/Root';
import { Detail } from './routes/Detail';
import { Create } from './routes/Create';
import { User } from './routes/User';
import { Layout } from './components/Layout';
import { Wallet } from './providers/wallet';
import DataProvider from './providers/data';
import { Landing } from './routes/Landing';
import { QueryProvider } from './providers/query';
import { RefcodeProvider } from './providers/refcode';
import SearchProvider from './providers/search';
import ModalProvider from './providers/modal';

const routerDev = createBrowserRouter([
  {
    path: '/landing',
    element: (
      <RefcodeProvider>
        <Landing />
      </RefcodeProvider>
    ),
  },
  {
    path: '/',
    element: (
      <Layout>
        <Root />
      </Layout>
    ),
  },
  {
    path: '/:mint',
    element: (
      <Layout>
        <Detail />
      </Layout>
    ),
  },
  {
    path: '/create',
    element: (
      <Layout>
        <Create />
      </Layout>
    ),
  },
  {
    path: '/user/:address',
    element: (
      <Layout>
        <User />
      </Layout>
    ),
  },
]);

const routerProd = createBrowserRouter([
  {
    path: '/',
    element: (
      <RefcodeProvider>
        <Landing />
      </RefcodeProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ModalProvider>
      <Wallet>
        <SearchProvider>
          <DataProvider>
            <QueryProvider>
              <RouterProvider
                router={
                  process.env.REACT_APP_SHOW_LANDING === '1'
                    ? routerProd
                    : routerDev
                }
              />
            </QueryProvider>
          </DataProvider>
        </SearchProvider>
      </Wallet>
    </ModalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
